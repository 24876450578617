import { ErrorCode } from './errorCode.js';

export class Base {
    constructor() {
        this.redirectUrl = '';
        this.baseUrl = window.location.protocol + "//" +  window.location.host;
        this.errorCode = new ErrorCode();
        this.resetRequest = new Request('POST', '/api/resetpassword');
        this.decryptRequest = new Request('POST', '/api/decrypt');
        this.decryptedCustomerId = {};
        this.baseInit();
    };

    baseInit() {
        document.querySelector('body').addEventListener('keydown', (event) => {
            this.enterSubmit(event);
        });

        this.redirectUrl = CONFIG.redirectUrl;
    }

    get registerRoute() {
        return this.baseUrl + "/registrierung" +  window.location.search;
    }

    get missingName() {
        return (CONFIG.registrationReason == "Kommentar" && CONFIG.nameless);
    }

    get missingNameRoute() {
        return this.baseUrl + "/ihr-name-fehlt" +  window.location.search;
    }

    get utpCookie() {
        return Cookies.get('__utp', false);
    }

    get consentCookie() {
        return Cookies.get('_pprv', false);
    }

    get secureCookie() {
        return !CONFIG.environment.isLocal;
    }

    set utpCookie(value) {
        let domain = location.hostname.replace("login.", ".");
        let options = { expires: 365, domain: domain, path: '/', sameSite: 'Lax', secure: this.secureCookie };

        if (value) {
            Cookies.set('__utp', value, options);

            if (!this.consentCookie) {
                Cookies.set('__shnozutp', value, options)
            }
        } else {
            Cookies.remove('__utp', options);
        }
    }

    set tacCookie(value) {
        let domain = location.hostname.replace("login.", ".");
        let options = { expires: 365, domain: domain, path: '/', sameSite: 'Lax', secure: this.secureCookie};

        if (value) {
            Cookies.set('__tac', value, options);
        }
    }

    enterSubmit(event) {
        // Overriden
    }

    updateTacCookie() {
        let tp = window.tp || [];
        if (tp.api && typeof tp.api.callApi === 'function') {
            tp.push(['setUsePianoIdUserProvider', true]);
            tp.api.callApi('/access/token/list', {}, (data) => {
                if (data.access_token_list) {
                    this.tacCookie = data.access_token_list.value;
                }
                document.location.href = this.redirectUrl;
            });
        } else {
            setTimeout(this.updateTacCookie, 1000);
        }
    }

    hideErrorMessages() {
        let errorMessages = document.querySelectorAll('.form-error');
        if (errorMessages) {
            errorMessages.forEach(errorMessage => {
                errorMessage.classList.add('d-none');
            });
        }
    }
    
    hideAllBlocks() {
        let blockers = document.querySelectorAll('.block');
        if (blockers) {
            blockers.forEach(block => {
                block.classList.add('d-none');
            });
        }
    }
    
    buttonSpinner(targetButton, show = true) {
        let button = (typeof targetButton === 'string') ? document.querySelector(targetButton) : targetButton;
        if(button) {
            button.style.width = button.offsetWidth + "px";
            
            if (show) {
                button.querySelector('span').classList.add('d-none');
                button.querySelector('div.lds-ellipsis').classList.remove('d-none');
            } else {
                button.querySelector('span').classList.remove('d-none');
                button.querySelector('div.lds-ellipsis').classList.add('d-none');
            }
        }
    }

    setStatusbar(percent) {
        let headerDiv = document.querySelector('.title');
        if (headerDiv) {
            headerDiv.classList.remove('verlauf');
            headerDiv.classList.remove('verlauf-50');
            headerDiv.classList.remove('verlauf-75');
            switch(percent) {
                case '25': headerDiv.classList.add('verlauf'); break;
                case '50': headerDiv.classList.add('verlauf-50'); break;
                case '75': headerDiv.classList.add('verlauf-75'); break;
            }
        }
    }

    getUrlParams() {
        const queryString = window.location.search;
        let data = [];
        let urlParams = new URLSearchParams(queryString);
        for (let pair of urlParams.entries()) {
            data[pair[0]] = pair[1];
        }
        return data;
    }
    
    pushHistoryState(form) {
        let path = '';
        switch (form) {
            case 'login': path = '/'; break
            case 'socialLogin': path = '/social-login'; break
            case 'socialLoginConfirm': path = '/social-login-confirm'; break
            case 'socialLoginConsensConfirm': path = '/social-login-consens-confirm'; break
            case 'googleExternalLink' : path = '/login-mit-google'; break
            case 'register': path = '/registrierung'; break
            case 'resetPassword': path = '/passwort-vergessen'; break
            case 'logout': path = '/logout'; break
            case 'addPersonalData': path = '/ihr-name-fehlt'; break
            case 'updateAccount': path = '/profil'; break
        }

        let data = {
            ref: CONFIG.redirectUrl,
            reason: CONFIG.registrationReason,
        }
        
        // AudioApp native login:
        if(CONFIG.environment.isAudioApp) {
            data.ref = "audioapp-shz://open_app";
        } else {
            // Referer check: Domain is one of our domains, value of ref is not "undefined" or "false"
            let refHost = data.ref.indexOf("http") !== -1 ? data.ref.match(/:\/\/(.[^/]+)/)[1] : data.ref;
            let sameDom = false;
            let refTld = ".de";
            let refDom = "";
            let arrRefHost = refHost.split(".");
            if (arrRefHost.length >= 2) {
                refTld = arrRefHost[arrRefHost.length - 1].indexOf(":") !== -1 ? arrRefHost[arrRefHost.length - 1].split(":")[0] : arrRefHost[arrRefHost.length - 1];
                refDom = arrRefHost[arrRefHost.length - 2] + "." + refTld
                sameDom = refDom.match(/^(shz|svz|nnn|noz|dk-online|om-online)\.(de|works|local)$/) ? true : false;
            }
            data.ref = sameDom === false ? (CONFIG.environment.tld == "local" ? "http://" : "https://") + "www." + CONFIG.environment.mandant + "." + CONFIG.environment.tld : data.ref; 
        }

        

        if (CONFIG.descriptionContext && CONFIG.descriptionContext != "default") {
            data.context = CONFIG.descriptionContext
        }

        if (form == "googleExternalLink") {
            data.socialType = "extendedAccess";
            console.log("push history state: data.socialType set to -> " + data.socialType);
        }


        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        for (let pair of urlParams.entries()) {
            let params = ['uid', 'utm_source', 'utm_medium', 'utm_campaign', 'itm_source', 'itm_medium', 'itm_campaign','gp', 'app', 'socialType'];
            if (params.includes(pair[0])) {
                data[pair[0]] = pair[1]
            }
        }

        //if (!CONFIG.environment.isNewsApp && !CONFIG.environment.isAudioApp) {
        if (!CONFIG.environment.isNewsApp) {
            let searchParams = new URLSearchParams(data)
            path += '?' + searchParams.toString()
            window.history.pushState({form:form}, '', path)
        }
    }

    async redirect() {
        await new Promise(resolve => setTimeout(resolve, 5000));
        console.log('Not activated or other apps customer! Redirect forced');
        document.location.href = this.redirectUrl;
    }

    showBlock(block) {
        let elements = (typeof block === 'string') ? document.querySelectorAll(block) : [block];
        if(elements) {
            elements.forEach(element => {
                element.classList.remove('d-none');
            });
        }
    }

    hideBlock(block) {
        let elements = (typeof block === 'string') ? document.querySelectorAll(block) : [block];
        if(elements) {
            elements.forEach(element => {
                element.classList.add('d-none');
            });
        }
    }

    resetPwConfirm(action) {
        let resetPwConfirmBlock = document.querySelector("#forgotPasswordConfirmation");
        if(action == "show") {
            this.showBlock(resetPwConfirmBlock);
            if(this.emailInput.value != "") {
                resetPwConfirmBlock.querySelector(".forgotPwEmail").innerHTML = this.emailInput.value;
            }
        } else {
            this.hideBlock(resetPwConfirmBlock);
        }
    }

    validateDecryptedGp(customerId) {
        let chkData = {
            gp: customerId,
        };
        let chkRequest = new Request('POST', '/api/validate/gp');
        chkRequest.data = chkData;
        chkRequest.call(resp => {
            if (resp.status == 'ok') {
                this.decryptedCustomerId.status = "ok";
                this.decryptedCustomerId.gp = customerId;
                return "ok";
            } else {
                if (resp.code == 10006) {
                    this.errorCode.login = resp.data.login;
                } 
                this.decryptedCustomerId.status = "error";
                this.decryptedCustomerId.gp = customerId;
                this.decryptedCustomerId.value = resp.code;
                return "error";
            }
        }, () => {
            this.requestError(this.errorCode.technicalError);
            return "error";
        });
    }

    decryptGp() {
        console.log("Encrypted GP found, decrypting.")
        let formData = {
            value:CONFIG.customerId,
        }
        this.decryptRequest.data = formData;
        this.decryptRequest.call(data => {
            if (data.status == 'ok') {
                this.validateDecryptedGp(data.data.value);
            } else {
                this.decryptedCustomerId.status = "error";
                this.decryptedCustomerId.value = 10005;
            }
        }, () => {
            this.requestError(this.errorCode.technicalError);
        });
    }

    resetPwShow() {
        this.buttonSpinner(".check-login-button");
        this.resetPwConfirm("hide");
        this.hideErrorMessages();

        if (this.emailInput.value == "") {
            document.location.href = this.baseUrl + "/passwort-vergessen" +  window.location.search;
        } else {
            let formData = {
                email:this.emailInput.value,
            };
            let errorMessage = "Es ist ein Fehler aufgetreten: <br />";
            this.resetRequest.data = formData;
            this.resetRequest.call(data => {
                this.buttonSpinner(".check-login-button", false);
                if (data.status == 'ok') {
                    forgotPasswordBlock.classList.add("d-none");
                    btnLoginBlock.classList.add("d-none");
                    passwordBlock.innerHTML = "<h1 class=\"main-headline\">Passwort zurücksetzen</h1><div class=\"divider main-headline-divider\"></div><p class=\"email-text main-description-text\">Wir haben Ihnen eine Nachricht an Ihre E-Mail-Adresse gesendet mit einem Link, unter dem Sie Ihr neues Passwort setzen können. Bitte schauen Sie in Ihrem Postfach nach.</p><p class=\"email-text\"><a href=\"" + window.location.protocol + "//" +  window.location.host  +  window.location.search + "\">Zurück zum Login</a></p>";
                } else {
                    errorMessage = errorMessage + response.message;
                    this.requestError(errorMessage);
                }
            }, () => {
                this.buttonSpinner(".check-login-button", false);
                this.requestError(this.errorCode.technicalError);
            });
        }
    }

    findCookieName(spName) {
        let cookiePosition = document.cookie.search(spName);
        let cookiename = document.cookie.substring(cookiePosition, (cookiePosition+16));
        return cookiename;
    }

    togglePwVisibility(id) {
        let toggleIcon = document.getElementById("toggleVisibility");
        let pwInput = document.getElementById(id);
        if (pwInput.type === "password") {
            pwInput.type = "text";
            toggleIcon.classList.add("visible");
        } else {
            pwInput.type = "password";
            toggleIcon.classList.remove("visible");
        }
    }

    checkPasswordStrength(password, button = '.btn.register-button') {
        let strength = 0;
        let asWord = "";
        let tips = "";
        let bars = document.querySelectorAll('.pwstrength.strengthcolor');
        let barWeak = document.querySelector('.pwstrength.strengthcolor.weak');
        let barOk = document.querySelector('.pwstrength.strengthcolor.ok');
        let barGood = document.querySelector('.pwstrength.strengthcolor.good');
        let blockTips = document.querySelector('.pwstrength.tips');
        let regBtn = document.querySelector(button);
        let error = 0;
      
        regBtn.disabled = true;
        // password length (required)
        if (password.length < 6) {
            tips += "<span style='color:#f00'>x Das Passwort muss aus mindestens 6 Zeichen bestehen (derzeit " + password.length + ").</span><br>";
            error = 1;
        } else {
            tips += "<span style='color:#00932D'>&check;</span><span>&nbsp; Das Passwort muss aus mindestens 6 Zeichen bestehen.</span><br>";
            strength += (password.length - 6);
        }
        // numbers (required)
        if (password.match(/\d/)) {
            strength += 1;
            tips += "<span style='color:#00932D'>&check;</span><span>&nbsp; Das Passwort muss mindestens eine Ziffer enthalten.</span><br>";
        } else {
            tips += "<span style='color:#f00'>x Das Passwort muss mindestens eine Ziffer enthalten.</span><br>";
            error = 1;
        }
        // special characters
        let count = (password.match(/[^a-zA-Z\d]/g) || []).length;
        if (count > 0) {
            strength += count;
        }
        // mixed case
        if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
            strength += 1;
        } 
    
        // results
        [].forEach.call(bars, function(bar) {
            bar.classList.add('d-none');
        });
        if ((strength >= 3) && (strength < 7) && (error === 0)) {
            barOk.classList.remove('d-none');
            asWord = '<span style="color:#FFE200;display:inline-block; margin-bottom:6px;font-style: italic;">Medium</span><br>'; 
        } else if ((strength >= 7) && (error === 0)) {
            barGood.classList.remove('d-none');
            asWord = '<span style="color:#00932D;display:inline-block; margin-bottom:6px;font-style: italic;">Stark</span><br>';
        } else {
            barWeak.classList.remove('d-none');
            asWord = '<span style="color:#f00;display:inline-block; margin-bottom:6px;font-style: italic;">Schwach</span><br>';
            console.log("Error: " + error + " | Stärke: " + strength + " | Count: " + count);
        }
        blockTips.classList.remove('d-none');
        blockTips.innerHTML = asWord + tips;
        if (error == 0) {
            regBtn.disabled = false;
        }
    }

    requestError(message) {
        let element = document.querySelector('.form-error.form-error-response > p');
        if (element) {
            element.innerHTML = message;
            this.showBlock('.form-error.form-error-response');
        }
    }
}